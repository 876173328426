import { useBreakpoint } from '@app/muiplus'
import { Button } from '@mui/material'
import React, { useCallback, useContext } from 'react'
import { signIn, signOut } from 'next-auth/react'
import { useSession } from 'src/services/useSession'

export const SessionStatus = function SessionStatus(props: any) {
	const [session, loading] = useSession()
	const isMobile = useBreakpoint('mobile')

	const logOut = useCallback(() => {
		signOut({ callbackUrl: '/' })

		return true
	}, [])
	const logIn = useCallback((context: any) => {
		signIn('google', { callbackUrl: '/Members/Home' }, { prompt: 'login' })
		return true
	}, [])
	if (session) return <Button onClick={logOut}>Logout</Button>
	return <Button onClick={logIn}>Login</Button>
}
